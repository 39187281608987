<template>
  <div class="offer mt-5">
    <div class="container">
      <div class="card">
        <div class="d-flex align-items-center">
          <span class="text-white">我的交易链接</span>
          <button class="btn btn-link text-primary text-small" @click="generateTradeToken">
            重新生成
          </button>
          <fa id="question-1" class="text-secondary pointer" :icon="['far', 'question-circle']" />
          <b-popover target="question-1" placement="top" triggers="hover focus" variant="secondary">
            如果您不再希望通过已经发布的交易 URL 收到新的交易报价，创建一条新的 URL 将使旧有链接失效。
          </b-popover>
        </div>
        <div class="text-secondary mt-1" style="font-size:0.8rem">
          您可以将这条唯一的 URL 分享给其他 Kitty 用户以允许他们向您发送交易报价，即使他们不是您的好友。您也可以将这条
          URL 发布在任何地方。
        </div>
        <div class="d-flex align-items-center mt-4">
          <search-input
            v-model="tradeUrl"
            style="flex:1"
            read-only
            hide-icon
            width="100%"
            :loading="regenerateLoading"
          />
          <btn
            id="popover-btn-1"
            class="btn btn-outline-secondary px-5 py-2 ml-4"
            :disabled="!tradeUrl || regenerateLoading"
            @click="copyURL"
            size="sm"
          >
            复制
          </btn>
          <b-popover target="popover-btn-1" :show.sync="pop" triggers="" placement="top" variant="success">
            复制成功
          </b-popover>
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-between mt-5">
        <tabs v-model="tab" :options="types" size="md">
          <template v-slot:pending="{ item }">
            <span class="d-flex align-items-center">
              {{ item.label }}
              <b-badge v-if="numOfOffers" pill class="ml-2 px-2" variant="light">{{ numOfOffers }}</b-badge>
            </span>
          </template>
        </tabs>
        <btn class="btn btn-primary" @click="startOffer">
          发起报价
        </btn>
      </div>

      <div class="d-flex justify-content-between flex-wrap my-4">
        <small v-if="filteredItems" class="found-label text-secondary"> 发现 {{ filteredItems.length }} 次报价 </small>
        <b-pagination
          v-model="currentPage"
          :total-rows="filteredItems.length"
          :per-page="perPage"
          first-number
          last-number
        />
      </div>

      <div
        v-if="!loading && filteredItems.length === 0"
        class="d-flex justify-content-center align-items-center text-secondary"
        style="width: 100%; height: 400px;background:rgba(37, 48, 67, 0.2)"
      >
        暂时没有报价哦~
      </div>

      <div v-if="!loading">
        <trade-card v-for="(i, k) in pagedItems" :key="k" :offer-item="i" @reload="hydratePage" />
      </div>

      <div v-if="loading">
        <b-skeleton v-for="i in 10" :key="i" width="100%" height="410px" class="m-0 my-3" />
      </div>

      <start-offer-modal @confirm="goNewOffer" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SearchInput from '@/components/SearchInput'
import { copyTextToClipboard } from '@/utils'
import TradeCard from './TradeCard.vue'
import { UserService, OfferService } from '@/services/api'
import { WEB_URL } from '../../config'
import StartOfferModal from './StartOfferModal.vue'

const TYPES = [
  { label: '进行中的报价', value: 'pending' },
  { label: '全部报价', value: 'all' },
  { label: '发出的报价', value: 'out' },
  { label: '收到的报价', value: 'in' },
]

export default {
  name: 'Offer',
  components: { SearchInput, TradeCard, StartOfferModal },
  data() {
    return {
      tab: TYPES[0],
      types: TYPES,
      loading: false,
      regenerateLoading: false,
      currentPage: 1,
      perPage: 5,
      // offerTradeLink: '',
      tradeUrl: '',
      pop: false,
    }
  },
  computed: {
    filteredItems() {
      if (this.tab.value === 'pending') {
        return this.allOffers.filter(i => i.status === 10)
      }
      if (this.tab.value === 'out') {
        return this.allOffers.filter(i => i.sender.steam_trade_url === this.user.info.steam_trade_url)
      }
      if (this.tab.value === 'in') {
        return this.allOffers.filter(i => i.receiver.steam_trade_url === this.user.info.steam_trade_url)
      }
      return this.allOffers
    },
    pagedItems() {
      const index = (this.currentPage - 1) * this.perPage
      const max = this.filteredItems.length
      return this.filteredItems.slice(index, Math.min(max, index + this.perPage))
    },
    ...mapGetters(['numOfOffers', 'user', 'allOffers']),
    tradeToken() {
      if (!this.user.info) return ''
      return this.user.info.trade_url
    },
  },
  watch: {
    currentPage() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    tradeToken(val) {
      this.tradeUrl = WEB_URL + '/offer/' + val + '/new'
    },
  },
  mounted() {
    this.hydratePage()
    // this.getRecentReceivers()
    if (this.user.info) {
      this.tradeUrl = WEB_URL + '/offer/' + this.user.info.trade_url + '/new'
    }
  },
  methods: {
    hydratePage() {
      this.loading = true

      OfferService.getAllOffers()
        .then(({ data }) => {
          const sorted = data.sort((a, b) => {
            return new Date(b.updated_at) - new Date(a.updated_at)
          })
          this.$store.commit('SET_ALL_OFFERS', sorted)
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    startOffer() {
      this.$bvModal.show('start-offer')
    },
    goNewOffer(receiver) {
      if (receiver.merchant_key) {
        this.$router.push({ path: `/offer/merchant/${receiver.merchant_key}/new` })
      } else {
        this.$router.push({ path: `/offer/${receiver.trade_url}/new` })
      }
    },
    async copyURL() {
      try {
        await copyTextToClipboard(this.tradeUrl)

        this.pop = true
        setTimeout(() => {
          this.pop = false
        }, 1000)
      } catch (err) {
        this.$store.commit('SET_ERROR', '复制出错')
      }
    },
    async generateTradeToken() {
      const ans = await this.$bvModal.msgBoxConfirm(`你确定要重新生成交易链接吗?`, {
        title: '生成交易链接',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: '确定',
        cancelTitle: '取消',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
      if (!ans) {
        return
      }
      this.regenerateLoading = true
      UserService.generateTradeToken()
        .then(async () => {
          const { data } = await UserService.getUserInfo()
          if (data && data.trade_url) {
            this.tradeUrl = WEB_URL + '/offer/' + data.trade_url + '/new'
          }
        })
        .catch(() => {})
        .finally(() => {
          this.regenerateLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../styles/style.scss';

.action {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
