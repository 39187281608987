<template>
  <b-modal id="start-offer" size="lg" centered hide-footer @hide="offerTradeLink = ''">
    <!-- <div class="action">
      <button class="btn btn-link" style="font-size: 22px;" @click="$bvModal.hide('start-offer')">
        <fa :icon="['far', 'times-circle']" />
      </button>
    </div> -->
    <template #modal-title>
      <span style="position:absolute;top:20px;left:50%;transform:translateX(-50%)">最近交易对象</span>
    </template>
    <div class="body p-4">
      <div class="d-flex">
        <search-input v-model="keyword" placeholder="输入账户名或ID进行搜索" width="100%" />
        <icon-btn class="btn-outline-secondary" :loading="refreshing" @click="getRecentReceivers">
          <fa :icon="['fa', 'redo']" />
        </icon-btn>
      </div>

      <!-- <div v-if="loading" class="receiver-box">
        <div class="receiver-card">
          <b-skeleton v-for="i in 10" :key="i" height="100%" width="100%" />
        </div>
      </div> -->

      <div class="mt-4 text-secondary text-sm">已绑定商家</div>

      <div class="receiver-box pt-3">
        <receiver-card
          v-for="(i, k) in availableMerchants"
          :key="'m' + k"
          :name="i.merchant_name"
          :id="i.merchant_user_id"
          :avatar_url="i.user_base_info.avatar_url"
          :updated_at="i.updated_at"
          @select="select(i)"
          :selectedReceiver="selectedReceiver"
          isMerchant
        />
      </div>

      <divider class="pl-2 pr-2" dashed />

      <div class="receiver-box" style="height:350px;">
        <receiver-card
          v-for="(i, k) in filteredItems"
          :key="'u' + k"
          :name="i.receiver.name"
          :id="i.receiver.id"
          :avatar_url="i.receiver.avatar_url"
          :updated_at="i.updated_at"
          @select="select(i)"
          :selectedReceiver="selectedReceiver"
        />
      </div>

      <div class="d-flex justify-content-center mt-4">
        <btn class="btn py-2 px-5 mr-4 btn-outline-secondary" @click="$bvModal.hide('start-offer')">
          取消
        </btn>
        <btn
          class="btn py-2 px-5 btn-primary"
          :disabled="!selectedReceiver.id && !selectedReceiver.merchant_key"
          @click="goNewOffer"
        >
          确定
        </btn>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import ReceiverCard from './ReceiverCard.vue'
import { OfferService } from '@/services/api'

export default {
  name: 'StartOfferModal',
  components: {
    ReceiverCard,
  },
  data() {
    return {
      offerTradeLink: '',
      keyword: '',
      items: [],
      selectedReceiver: {},
      refreshing: false,
      merchantItems: [],
    }
  },
  computed: {
    ...mapGetters(['user']),
    filteredItems() {
      const filteredItems = this.items.filter(i => {
        if (!this.keyword) {
          return true
        }
        if (!i.name) {
          return false
        }
        return i.name.includes(this.keyword)
      })
      return filteredItems
    },
    availableMerchants() {
      const boundedMerchants = this.user.info.bound_merchant ?? []
      boundedMerchants.forEach(i => {
        const m = this.merchantItems.find(merchant => i.merchant_key === merchant.merchant_key)
        if (m) {
          i.user_base_info = m.user_base_info
        } else {
          i.user_base_info = {
            avatar_url: '',
            id: '',
            name: '',
          }
        }
      })
      console.log(boundedMerchants)
      return boundedMerchants
    },
  },
  mounted() {
    this.getRecentReceivers()
    this.getMerchantInfo()
  },
  methods: {
    goNewOffer() {
      this.$emit('confirm', this.selectedReceiver)
      // console.log(this.selectedReceiver)
      // if (this.selectedReceiver.merchant_key) {
      //   this.$router.push({ path: `/offer/merchant/${this.selectedReceiver.merchant_key}/new` })
      // } else {
      //   this.$router.push({ path: `/offer/${this.selectedReceiver.trade_url}/new` })
      // }
    },
    getRecentReceivers() {
      this.refreshing = true
      OfferService.getRecentReceivers()
        .then(({ data }) => {
          this.items = data
        })
        .finally(() => {
          this.refreshing = false
        })
    },
    getMerchantInfo() {
      OfferService.getAllMerchants()
        .then(({ data }) => {
          this.merchantItems = data
        })
        .finally(() => {
          this.refreshing = false
        })
    },
    select(item) {
      if (item.merchant_key) {
        this.selectedReceiver = item
      } else {
        this.selectedReceiver = item.receiver
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.receiver-box {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow: auto;
}

.receiver-card {
  height: 106px;
  padding: 10px;
}
</style>
