<template>
  <div class="receiver-card" @click="select" :class="{ 'receiver-card--selected': selected }">
    <div class="receiver-card__wrapper">
      <div class="d-flex text-9">
        <b-avatar rounded :src="avatar_url" />
        <div class="pl-3">
          <div>{{ name }}</div>
          <div>ID: {{ id }}</div>
        </div>
      </div>
      <div v-if="updatedAt" class="text-secondary text-7 pt-1" style="line-height:20px">最近交易 {{ updatedAt }}</div>

      <small v-if="isMerchant" class="text-danger text-7">平台商家</small>
    </div>
  </div>
</template>

<script>
import { isValidDate } from '@/utils'

export default {
  name: 'ReceiverCard',
  props: {
    name: String,
    id: [String, Number],
    avatar_url: String,
    updated_at: String,
    selectedReceiver: { type: Object },
    isMerchant: Boolean,
  },
  data() {
    return {
      // item: {},
    }
  },
  computed: {
    updatedAt() {
      const dt = new Date(this.updated_at)
      if (isValidDate(dt)) {
        return dt.toLocaleString('zh-CN')
      }
      return ''
    },
    selected() {
      if (this.selectedReceiver.merchant_key) {
        return this.selectedReceiver.merchant_user_id === this.id
      }
      return this.selectedReceiver.id === this.id
    },
  },
  methods: {
    select() {
      this.$emit('select')
    },
  },
}
</script>

<style lang="scss" scoped>
.receiver-card {
  height: 106px;
  padding: 10px;

  @media screen and (min-width: 992px) {
    flex: 0.3333 0.3333 100%;
    max-width: 33.33%;
  }

  @media screen and (max-width: 991px) {
    flex: 50%;
    max-width: 50%;
  }

  @media screen and (max-width: 600px) {
    flex: 100%;
    max-width: 100%;
  }

  &--selected {
    .receiver-card__wrapper {
      border-color: #bbb;
    }
  }
}

.receiver-card__wrapper {
  padding: 12px 14px 6px;
  background: #1c2028;
  border: 1px solid #253043;
  box-sizing: border-box;
  border-radius: 4px;
}
</style>
