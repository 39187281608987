<template>
  <div class="trade-card">
    <div class="trade-card__header">
      <span class="text-white">
        <span v-if="senderIsMe">
          我向
          <span class="text-primary">
            {{ receiver.name }}
          </span>
          <span>{{ ` (ID: ${receiver.id})` }}</span>
        </span>
        <span v-if="!senderIsMe">
          <span class="text-primary">{{ sender.name }}</span>
          <span>{{ ` (ID: ${sender.id})` }}</span>
          向我
        </span>
        发起交易
      </span>
      <span
        class=""
        :class="{
          'text-danger': offerItem.status === 10,
          'text-primary': offerItem.status === 20,
          'text-secondary': offerItem.status !== 10 && offerItem.status !== 20,
        }"
        >{{ statusText }}</span
      >
    </div>

    <div class="trade-card__body">
      <div class="i-row">
        <div class="col pl-0">
          <div class="i-row">
            <b-avatar rounded :src="sender.avatar_url" />
            <div class="pl-3">
              <div class="text-white">
                <span v-if="senderIsMe">我 </span>
                <span v-else>对方 </span>
                ({{ sender.name }}) 的报价
              </div>
              <div class="text-secondary text-9">
                以下是您在本次交易中
                <span class="text-primary">{{ senderIsMe ? '失去' : '得到' }}</span>
                的饰品
              </div>
            </div>
          </div>
          <div
            class="i-row p-2 items-container"
            :class="{ 'line-2': offerItem.sender_inventories && offerItem.sender_inventories.length > 4 }"
          >
            <offer-item v-for="(i, k) in offerItem.sender_inventories" :key="k" :inventory="i" disabled />
          </div>
        </div>

        <div class="col pr-0">
          <div class="i-row">
            <b-avatar rounded :src="receiver.avatar_url" />
            <div class="pl-3">
              <div class="text-white">
                <span v-if="!senderIsMe">我 </span>
                <span v-else>对方 </span>
                ({{ receiver.name }}) 的报价
              </div>
              <div class="text-secondary text-9">
                以下是您在本次交易中
                <span class="text-primary">{{ senderIsMe ? '得到' : '失去' }}</span>
                的饰品
              </div>
            </div>
          </div>
          <div
            class="i-row p-2 items-container"
            :class="{ 'line-2': offerItem.receiver_inventories && offerItem.receiver_inventories.length > 4 }"
          >
            <offer-item v-for="(i, k) in offerItem.receiver_inventories" :key="k" :inventory="i" disabled />
          </div>
        </div>
      </div>
    </div>

    <div class="trade-card__footer">
      <div>
        <div v-if="offerItem.message" class="text-secondary px-3" style="background:#25304333;line-height:44px">
          <span>备注：</span>
          {{ offerItem.message }}
        </div>
      </div>
      <div class="d-flex align-items-center ml-auto">
        <div class="text-secondary">创建时间：{{ createdAt }}</div>
        <div v-if="!noAction">
          <btn v-if="!senderIsMe && offerItem.status === 10" class="btn btn-outline-primary ml-3" @click="respond(id)">
            回应报价
          </btn>
          <btn v-if="senderIsMe && offerItem.status === 10" class="btn btn-outline-secondary ml-3" @click="cancel">
            取消报价
          </btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { OfferService } from '@/services/api'
import OfferItem from './OfferItem.vue'

const INIT = {
  avatar_url: '',
  balance: 0,
  hide_inventory: false,
  id: 0,
  name: '',
  steam_create_time: 0,
  steam_id: '',
  steam_trade_url: null,
  trade_url: '',
}

export default {
  name: 'TradeCard',
  components: { OfferItem },
  props: {
    offerItem: {
      type: Object,
      default: function() {
        return INIT
      },
    },
    loading: { type: Boolean, default: false },
    noAction: { type: Boolean, default: false },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['user']),
    receiver() {
      if (!this.offerItem.offer_id) return INIT
      return this.offerItem?.receiver
    },
    sender() {
      if (!this.offerItem.offer_id) return INIT
      return this.offerItem?.sender
    },
    id() {
      return this.offerItem?.offer_id
    },
    senderIsMe() {
      return this.offerItem?.sender?.id === this.user?.info?.id
    },
    updatedAt() {
      const dt = new Date(this.offerItem.updated_at)
      return dt.toLocaleString('zh-CN')
    },
    createdAt() {
      const dt = new Date(this.offerItem.created_at)
      return dt.toLocaleString('zh-CN')
    },
    statusText() {
      /**
       * 10 进行中
       * 20 已接收
       * 30
       * 40
       * 50 已取消
       * 60 已拒绝
       */
      switch (this.offerItem.status) {
        case 10:
          return this.senderIsMe ? '等待对方处理' : '待处理'
          break
        case 20:
          return `已于 ${this.updatedAt} 完成报价`
          break
        case 30:
          return ''
          break
        case 40:
          return ''
          break
        case 50:
          return this.senderIsMe ? `已于 ${this.updatedAt} 取消报价` : `已于 ${this.updatedAt} 被取消报价`
          break
        case 60:
          return this.senderIsMe ? `已于 ${this.updatedAt} 被拒绝` : `已于 ${this.updatedAt} 拒绝报价`
      }
      return ''
    },
    statusBg() {
      switch (this.offerItem.status) {
        case 10:
          return ''
          break
        case 20:
          return 'rgba(113, 215, 79, 0.4)'
          break
        case 50:
          return 'rgba(123, 139, 167, 0.4)'
          break
        case 60:
          return 'rgba(114, 51, 54, 0.4)'
      }
      return ''
    },
  },
  methods: {
    toast(message, type = 'warning') {
      this.$bvToast.toast(message, {
        title: message,
        toaster: 'b-toaster-bottom-right',
        variant: type,
        solid: true,
      })
    },
    accept() {
      this.loading = true
      OfferService.acceptOffer(this.offerItem.offer_id)
        .then(() => {
          this.$emit('reload')
          this.toast('接受报价成功', 'success')
        })
        .catch(() => {
          this.toast('接受失败')
        })
        .finally(() => {
          this.loading = false
        })
    },
    reject() {
      this.loading = true
      OfferService.rejectOffer(this.offerItem.offer_id)
        .then(() => {
          this.$emit('reload')
          this.toast('已拒绝报价', 'success')
        })
        .catch(() => {
          this.toast('拒绝失败')
        })
        .finally(() => {
          this.loading = false
        })
    },
    respond(id) {
      this.$router.push(`/offer/${id}`)
    },
    cancel() {
      this.loading = true
      OfferService.cancelOffer(this.offerItem.offer_id)
        .then(() => {
          this.toast('已取消报价', 'success')
          this.$emit('reload')
        })
        .catch(() => {
          this.toast('取消失败')
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../styles/style.scss';

.items-container {
  min-height: 146px;
  height: calc(100% - 48px);
  width: 100%;
  background: #0b0e18;
  overflow: auto;

  &.line-2 {
    height: 276px;
  }
}

.offer-item {
  flex: 0.25 0.25 100%;
  max-width: 25%;
}

.trade-card {
  width: 100%;
  margin-bottom: 20px;
  position: relative;

  &:hover {
    .trade-card__mask {
      opacity: 0;
    }
  }

  &__mask {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 1;
    background: rgba($color: #000000, $alpha: 0.2);
    transition: 0.3s ease;

    .mask-banner {
      width: 100%;
      height: 80px;
      background: rgba($color: $primary, $alpha: 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: white;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    padding: 30px;
    background: $bg-opacity;
    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 60px,
      calc(100% - 5px) calc(100% - 19.37px),
      calc(100% - 10px) calc(100% - 17.32px),
      calc(100% - 15px) calc(100% - 13.23px),
      calc(100% - 16px) calc(100% - 12px),
      calc(100% - 17px) calc(100% - 10.54px),
      calc(100% - 18px) calc(100% - 8.72px),
      calc(100% - 19px) calc(100% - 6.25px),
      calc(100% - 19.5px) calc(100% - 4.44px),
      calc(100% - 20px) 100%,
      20px 100%,
      19.5px calc(100% - 4.44px),
      19px calc(100% - 6.25px),
      18px calc(100% - 8.72px),
      17px calc(100% - 10.54px),
      15px calc(100% - 13.23px),
      10px calc(100% - 17.32px),
      5px calc(100% - 19.37px),
      0 60px
    );
    border-bottom: 1px dashed #2d3139;
  }
  &__body {
    width: 100%;
    padding: 30px;
    background: $bg-opacity;
    border-top: 1px dashed #2d3139;
    border-bottom: 1px dashed #2d3139;
    clip-path: polygon(
      0% 20px,
      5px 19.37px,
      10px 17.32px,
      15px 13.23px,
      16px 12px,
      17px 10.54px,
      18px 8.72px,
      19px 6.25px,
      19.5px 4.44px,
      20px 0%,
      calc(100% - 20px) 0%,
      calc(100% - 19.5px) 4.44px,
      calc(100% - 19px) 6.25px,
      calc(100% - 18px) 8.72px,
      calc(100% - 17px) 10.54px,
      calc(100% - 16px) 12px,
      calc(100% - 15px) 13.23px,
      calc(100% - 10px) 17.32px,
      calc(100% - 5px) 19.37px,
      100% 20px,
      100% 100%,
      0 100%
    );
  }
  &__footer {
    width: 100%;
    padding: 20px;
    background: $bg-opacity;
    display: flex;
    justify-content: space-between;
  }
}

.img {
  width: 120px;
  height: 100px;
  background-color: #1c2028;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
